<script>
  const tileBands = document.querySelectorAll('.cmp-tile-band');
  tileBands.forEach(tileBand => {
    const bgURL = tileBand.getAttribute('data-bg');
    if(bgURL && bgURL!=null){
      tileBand.style.backgroundImage = `url(${bgURL})`;
      tileBand.classList.add('cmp-tile-band--has-bg-image');
    }
    const tilesCount = tileBand.querySelectorAll('.cmp-tile-band__tiles').length;
    if(tilesCount === 4) {
      tileBand.querySelector('.cmp-tile-band__container').classList.add('cmp-tile-band--four-tiles');
    }
  });

import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent
}
</script>

<style lang="scss">
@import './tile-band.scss';
</style>
