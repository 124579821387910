<script>
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue'

export default {
  extends: CoreComponent,

  created (){
        this.cmpEl.querySelectorAll("a").forEach(element => element.addEventListener("click", 
        function(){
            this.$store.dispatch('trackLink', {
                name: element.innerText || 'undefined',
                position: this.cmpEl.id
            })
        }.bind(this)
        ))
        
    }
}
</script>

<style lang="scss">
  @import './flexband.scss';
</style>
