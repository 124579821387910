<script>
import CoreComponent from "../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue";

export default {
  extends: CoreComponent,

  mounted() {
    const searchComponent = this.cmpEl.querySelector(
      ".cmp-globalHeader_search-container"
    );
    const searchIcon = this.cmpEl.querySelector(".search-icon");

    searchIcon.addEventListener("click", () => {
      if (searchComponent.classList.contains("hide-search")) {
        searchComponent.classList.remove("hide-search");
      } else {
        searchComponent.classList.add("hide-search");
      }
    });
  },
};
</script>

<style lang="scss">
@import "./GlobalHeader.scss";
</style>
